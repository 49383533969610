.rdrMonthName {
  text-align: center !important;
  font-weight: 600;
  color: #5c6164 !important;
  padding: 0.833em;
  border: 1px solid #dddddd !important;
  border-radius: 7px;
  white-space: nowrap ;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #0092ff !important;
}

.rdrDayEndPreview,
.rdrDayInPreview,
.rdrDayStartPreview {
  border-color: #0092ff !important;
}

.cal-icn {
  width: 17px;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span {
  color: rgb(255 255 255) !important;
  font-weight: 500 !important;
}
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span{
  color: rgb(255 255 255) !important;
  font-weight: 500 !important;
}
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
  color: black !important;
  font-weight: 500 !important;
}


.rdrStaticRangeLabel {
  padding: 5px 10px !important;
}

.rdrDateDisplay {
  margin: 5px !important;
}

.rdrMonthAndYearWrapper {
  height: auto !important;
  margin: 5px 0px !important;
  padding: 0px !important;
}

.rdrDay {
  line-height: 1.75rem !important;
  height: 1.75rem !important;
}

.rdrMonth {
  width: 220px !important;
  position: relative;
  padding: 0 5px !important;
}

.rdrMonthName {
  position: absolute;
  top: -23px;
  left: 0;
  right: 0;
  border: 0 !important;
  width: 100px;
  margin: auto;
  padding: 0 !important;
}

.rdrDayToday .rdrDayNumber span:after {
  bottom: 0px !important;
}

.rdrDateDisplayWrapper {
  background-color: #ffffff;
  margin-bottom: 28px;
  margin-top:10px
}

.rdrDateDisplayWrapper {
  background-color: #ffffff;
}

.rdrDateDisplayItem  {
  box-shadow:none;
  border:1px solid #D0D5DD;
  border-radius: 8px;
  text-align: left;
  padding-left: 8px;
}

.rdrDateDisplayItem input {
  color:#222124;
}

.rdrMonth {
  width: 300px !important;
}

.rdrDay {
  height: 2.5rem !important;
}

.rdrMonthName {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 9px !important;
  color:#344054 !important
}

.rdrEndEdge {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dat_btn {
  padding: 6px 8px !important;
}

.rdrNextPrevButton.rdrPprevButton,
.rdrNextPrevButton.rdrNextButton {
  background-color: transparent !important;
  background: transparent !important;
  border:1px solid #E6E6E6;
}

.rdrPprevButton i {
  border-width: 4px!important;
  border-color: transparent #b9b4b4 transparent transparent !important;
  content: 'suih';
}

.rdrNextButton i {
  border-width: 4px !important;
  border-color: transparent transparent transparent #b9b4b4 !important;
}

.rdrNextPrevButton.rdrPprevButton i {
  border-color: transparent #b9b4b4 transparent transparent !important;
}

.rdrWeekDay {
  font-size: 12px !important;
  font-weight: 700 !important;
  color:#344054
}

.rdrDayEndOfMonth{
  color:gray !important
}

.rdrDayNumber span {
  color: #000 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.rs-anim-fade.rs-anim-in.rs-popover {
  padding: 0px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px #00000040;
}

.rs-popover[class*=placement-left]>.rs-popover-arrow::after {
  margin-top: 13px !important;
}


.rdrMonth {
  min-height: 250px !important;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.cus_date-select{
  font-size: 12px;
  color: #333843B2;
}


.hide_month{
  .rdrMonthPicker{
    display: none;
  }
}

.rdrInRange {
  background-color:#D7E3FC; 
  color:#222124 !important; 
}

.rdrMonthAndYearPickers{
  display: none;
}

.rdrDay .rdrStartEdge{
  background-color: #1967d2;
  border-radius: 12px 0px 0px 12px !important;
}

.rdrDay .rdrEndEdge{
  background: #1967d2;
  border-radius: 0px 12px 12px 0px !important;
}

.rdrDay .rdrInRange .rdrDayNumber {
  font-weight: bold !important;
}


