.App {
  text-align: center;
  font-family: 'Inter';
}

.PhoneInputInput {
height:22px;
border:none;
background-color: #f8f1f182;
padding:4px
}

.swal2-container {
  z-index: 20000 !important;
}